import BlockContent from "@sanity/block-content-to-react";
import Layout from "components/common/layout/layout";
import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";

import Sidebar from "../components/blog/Sidebar/Sidebar";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";

const PageArticle = ({ data, pageContext }) => {
  const { article } = pageContext;
  const { dataset, projectId } = data.site.siteMetadata;
  const categories = data.categories.nodes;
  return (
    <Layout>
      <section>
        <div className={"o-hero o-hero--blog"}>
          <div className="container p-vertical-xl post-hero">
            <h1 className={"a-title a-title--hero a-title--orange"}>
              <strong style={{ color: "#EB6C34" }}> {article.title}</strong>{" "}
              <br />
              {article.category.name}
            </h1>
            <div className="border">
              <SanityImage
                {...article.thumbnail}
                width={960}
                alt={article.thumbnailText}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="post-container">
        <main className={"post-content"}>
          <BlockContent
            blocks={article._rawBody}
            dataset={dataset}
            projectId={projectId}
          />
        </main>
        <aside>
          <Sidebar categories={categories} />
        </aside>
      </div>
    </Layout>
  );
};

export const Head = ({ pageContext }) => (
  <SEO
    title={`${pageContext.article.seo_title}`}
    description={pageContext.article.seo_description}
    robots={pageContext.article.seo_robots}
    canonical={`${pageContext.article.slug.current}/`}
    image={`${pageContext.article.thumbnail.asset.gatsbyImageData.images.fallback.src}`}
  />
);

export const query = graphql`
  query sanityData {
    site {
      siteMetadata {
        projectId
        dataset
      }
    }
    categories: allSanityCategory(sort: { fields: name, order: ASC }) {
      nodes {
        name
        slug {
          current
        }
        id
      }
    }
  }
`;

export default PageArticle;
